<script setup lang="ts">
import { defineProps, withDefaults } from "vue";
import { SystemLookupProgress } from "o365.pwa.modules.client.steps.SystemLookupProgress.ts";

interface IProps {
    syncStepProgress: SystemLookupProgress;
    currentStep: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
    currentStep: false,
});

</script>

<script lang="ts">
export default {
    name: "SystemLookupProgress",
};
</script>


<template>
    <div class="card shadow-sm mb-3 w-100">
        <div class="card-header d-flex justify-content-between text-nowrap">
            <div class="d-flex flex-column">
                <!-- Definition -->
                <div class="fw-bold">
                    <span>{{ props.syncStepProgress.title }}</span>
                </div>
                <!-- TITLE -->
                <div class="small-subtitle">
                    System Lookups
                </div>
            </div>
            <!-- STATUS -->
            <div class="d-flex justify-content-center">
                <template v-if="props.syncStepProgress._syncStatus !== 10">
                    <span class="text-black d-flex align-items-center gap-1"
                        v-if="!props.syncStepProgress.systemLookupHasStarted && !props.syncStepProgress.systemLookupHasCompleted">
                        <i class="bi bi-pause-circle-fill text-black"></i> Waiting to start
                    </span>
                    <span class="text-success d-flex align-items-center gap-1"
                        v-if="props.syncStepProgress.systemLookupHasCompleted && !props.syncStepProgress.systemLookupHasErrors">
                        <i class="bi bi-check-circle-fill text-success"></i> Completed
                    </span>
                    <span class="text-danger d-flex align-items-center gap-1"
                        v-if="props.syncStepProgress.systemLookupHasErrors">
                        <i class="bi bi-exclamation-circle-fill text-danger"></i> Error completing
                    </span>
                </template>
                <template v-if="props.syncStepProgress._syncStatus === 10">
                    <span class="text-danger d-flex align-items-center gap-1">
                        <i class="bi bi-x-octagon-fill text-danger"></i> Cancelled
                    </span>
                </template>
            </div>
        </div>
        <div class="card-body">
            <div>
                <div v-if="props.syncStepProgress.systemLookupHasStarted && !props.syncStepProgress.systemLookupHasCompleted && !props.syncStepProgress.systemLookupHasErrors"
                    class="d-flex align-items-center justify-content-between gap-1">
                    <div>Preparing to load system lookup data...</div>
                    <div>
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                <div v-if="props.syncStepProgress.systemLookupHasErrors && !props.syncStepProgress.systemLookupHasCompleted"
                    class="d-flex align-items-center justify-content-between gap-1 text-danger-subtle">
                    <div>Failed to load system lookup data</div>
                    <div>
                        <i class="bi bi-exclamation-circle-fill text-danger"></i>
                    </div>
                </div>
                <div v-if="props.syncStepProgress.systemLookupHasCompleted"
                    class="d-flex align-items-center justify-content-between gap-1">
                    <div>Completed to load system lookup data</div>
                    <div><i class="bi bi-check-circle-fill text-success"></i></div>
                </div>
                <div v-if="props.syncStepProgress.uiFriendlyMessages.length > 0">
                    <a class="text-danger fs-6 text-decoration-none" data-bs-toggle="collapse"
                        :href="'#' + props.syncStepProgress.title.replaceAll(' ', '')" role="button" aria-expanded="false"
                        aria-controls="collapseExample">
                        Show errors ({{ props.syncStepProgress.uiFriendlyMessages.length }})
                    </a>
                </div>
                <template v-if="syncStepProgress.uiFriendlyMessages.length > 0">
                    <div class="collapse" :id="props.syncStepProgress.title.replaceAll(' ', '')">
                        <div class="card card-body d-flex bg-danger-subtle w-100">
                            <ul class="list-group w-100">
                                <li v-for="uiFriendlyMessage in syncStepProgress.uiFriendlyMessages"
                                    class="list-group-item list-group-item-danger d-flex flex-column">
                                    <div class="fw-bold fs-6 text-nowrap">
                                        {{ uiFriendlyMessage.title }}
                                    </div>
                                    <div class="fs-6">
                                        {{ uiFriendlyMessage.body }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>

    <component v-for="(SyncStepProgress, index) in syncStepProgress.stepsProgress" :is="SyncStepProgress.vueComponentName" :syncStepProgress="SyncStepProgress" :currentStep="currentStep"/>
</template>

<style scoped>
.small-subtitle {
    font-size: 12px;
}
</style>
